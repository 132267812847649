.product {
    margin: 1rem 0 0 0;
    position: relative;
    border: 1px solid $primary !important;
    border-radius: $borderRadius;
    max-width: 360px;
    font-family: 'Netto', sans-serif;

    @include media-breakpoint-up(md) {
        margin: 3rem 0 0 0;
    }

    &.product-horizontal{
        max-width: none;
        padding: 2rem;
        margin: 0;

        .product-save{
            font-size: 14px;
            color: $purple;
            margin-bottom: 0.5rem;
        }

        .product-price{
            font-size: 30px;
            margin-bottom: 0.5rem;
        }

        .product-old-price{
            font-size: 14px;
        }
    }

    .product-bestseller{
        position: relative;
        height: 2rem;
        width: 100%;
        border-radius: $borderRadius $borderRadius 0 0;
        background: $primary;
        color: $white;
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        line-height: 2rem;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: -3rem;
            height: 3rem;
            font-size: 1.5rem;
            line-height: 3rem;
        }
    }

    .product-new{
        position: relative;
        height: 2rem;
        width: 100%;
        border-radius: $borderRadius $borderRadius 0 0;
        background: $red;
        color: $white;
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        line-height: 2rem;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: -3rem;
            height: 3rem;
            font-size: 1.5rem;
            line-height: 3rem;
        }
    }

    .card-header, .card-footer, .card-body{
        background: none !important;
        border: none;
        text-align: center;
        padding: 1rem;
    }

    .card-body{
        padding-top: 0;
    }

    .product-name{
        color: $primary;
        font-size: 1.75rem;
        text-transform: uppercase;

        .product__brand{
            font-weight: bold;
            color: $black;
        }
    }

    .product-old-price{
        text-align: center;
        display: block;
        text-decoration: line-through;
    }

    .product-save{
        display: block;
        text-align: center;
        font-size: 1.25rem;
        color: $purple;
    }

    .product-price{
        display: block;
        text-align: center;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 1.2;
        color: $black;
    }

    ul{
        text-align: left;
        margin: 2rem 0 0 0;
    }

    .lenght-label{
        display: block;
        margin: 0 0 1rem 0;
        font-size: 1.5rem;
        color: $grey;
    }
}

.card-deck{
    justify-content: center;

    .product {
        @include media-breakpoint-up(sm) {
            min-width: 255px;
            max-width: 255px;
        }

        @include media-breakpoint-up(md) {
            min-width: 220px;
            max-width: 220px;
        }
        @include media-breakpoint-up(lg) {
            min-width: 217.5px;
            max-width: 217.5px;
        }

        @include media-breakpoint-up(xl) {
            min-width: 262.5px;
            max-width: 262.5px;
        }
    }
}

.card-deck.multi-products {
    display: flex;
    flex-flow: row nowrap;
    margin-right: -15px;
    margin-left: -15px;
    overflow-x: auto;
    overflow-y: visible;

    &:after {
        content: '';
        flex: 0 0 15px;

        @include media-breakpoint-up(sm) {
            flex: 0;
        }
    }

    .product {
        margin-left: 15px;
        min-width: 85%;

        @include media-breakpoint-up(sm) {
            min-width: 360px;
        }
    }
}
