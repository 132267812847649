.envIndicator {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zIndexEnvIndicator;
  font-size: 2em;
  text-align: center;
  line-height: 1;
  font-weight: bold;
  background-color: white;
  overflow: hidden;
}

.envIndicator__label + .envIndicator__label::before {
  content: '+';
  color: black;
  background: white;
}

.envIndicator__label {
  position: relative;
  display: inline-block;
  color: white;
}

.envIndicator__label--dev {
  background: $devColor;
}

.envIndicator__label--stage {
  background: $stageColor;
}

.envIndicator__label--prod {
  background: $prodColor;
}

@supports (pointer-events: none) {
  .envIndicator {
    font-size: 7em;
    font-style: italic;
    pointer-events: none;
    opacity: 0.2;
    overflow: visible;
    background: transparent;
    line-height: 0.8;
    text-align: right;
  }

  .envIndicator__label,
  .envIndicator__label + .envIndicator__label::before {
    background: transparent;
  }

  .envIndicator__label--dev {
    color: $devColor;
    padding-right: $italicDevCompensation;
  }

  .envIndicator__label--stage {
    color: $stageColor;
  }

  .envIndicator__label--prod {
    color: $prodColor;
  }

  .envIndicator__type
  {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.3em;
  }
}
