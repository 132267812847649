.page-template-cart {
    main{
        font-family: 'Netto', sans-serif;
        background: $light-grey;

        header {
            padding: 0 0 2rem 0;
    
            @include media-breakpoint-up(md) {
                padding: 2rem 0;
            }
        }
    
        section {
            padding: 0;
            border-bottom: none;
        }
    
        h2 {
            color: $primary;
            font-size: 18px;
            margin-bottom: 2rem;
        }
    
        .btn-link{
            color: $primary;
            text-decoration: underline;
        }

        .line{
            position: relative;
            text-align: center;
            margin: 1rem 0;
            font-size: 14px;
            color: $grey3;
            
            span{
                position: relative;
                display: inline-block;
                background: $white;
                padding: 0 1rem;
            }

            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background: $light-grey2;
                top: 50%;
                left: 0;
            }
        }
        
        .product-thumbnail{
            width: 100px;
            height: auto;
        }
    
        .price {
            text-align: right;
        }
    
        .payment-method-form {
            .collapse.show {
                display: inline;
            }
        }
    
        span.collapse-arrow {
            display: inline-block;
            color: $primary;
            transition: all 0.3s ease-in-out;
    
            &.open {
                transform: rotate(180deg);
            }
        }
    
        .cart-product {
            .product-row {
                border-bottom: 1px solid $light-grey;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 14px;

                &:nth-child(2){
                    padding-top: 0;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    align-items: center;
                }

                .product-name{
                    font-size: 1rem;
                    font-weight: 600;
                }
            }
        }
    
        .cart-summary {
            .row {
                border-bottom: 1px solid $light-grey;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 1rem;
    
                &.delivery-option-price {
                    border-bottom: 1px solid $primary;
                }

                &:nth-child(2){
                    padding-top: 0;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }

            .summary-product {
                border-bottom: 1px solid $light-grey;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 14px;

                &:nth-child(2){
                    padding-top: 0;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    align-items: center;
                }

                .product-name{
                    font-size: 1rem;
                    font-weight: 600;
                }
            }
        }
    
        .payment-method {
            p {
                font-size: 14px;
                color: $grey3;
            }
    
            .payment-logo {
                max-height: 55px;
            }
        }

        .product {
            margin-top: 0;
            margin-bottom: 1rem;

            @include media-breakpoint-up(sm){
                margin-bottom: 2rem;
            }

            &.product-horizontal{
                max-width: none !important;
            }

            .product-name{
                color: $primary;
                font-size: 20px;

                .product__brand{
                    color: $black;
                }
            }

            .product-save{
                font-size: 14px;
                color: $purple;
                margin-bottom: 0.5rem;
            }

            .product-price{
                font-size: 30px;
                margin-bottom: 0.5rem;
            }

            .product-old-price{
                font-size: 14px;
            }
        }
    
    
        .product-details {
            text-align: center;
            padding: 4rem 0;
            height: calc(100vh - 1rem - 2px);
        
            @include media-breakpoint-up(sm) {
                height: calc(100vh - 3.5rem - 2px);
            }
        
            .product-name {
                display: block;
                color: $dark-grey;
                font-weight: 500;
                font-size: 2rem;
            }
        
            .product-description,
            .product-save {
                display: block;
                color: $dark-grey;
                font-size: 2rem;
            }
        
            .product-old-price {
                display: block;
                color: $grey;
                font-size: 2rem;
                text-decoration: line-through;
            }
        
            .product-price {
                font-weight: 500;
                display: block;
                color: $green;
                font-size: 3rem;
            }
        }

    }
}
