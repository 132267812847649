$contentVerticalPadding: 1.5rem;
$transitionDuration: 200ms;
$immediateTransitionDuration: 50ms;
$transition: $transitionDuration linear;
$legendMaxWidth: 15em;
$scaleColour: $light-grey;
$scaleLineHeight: 3rem;
$scaleLabelSize: 1rem;
$scaleLabelSizeChosen: 1.5rem;
$scaleLabelColourActive: $primary;
$thumbSize: 2 / 3 * $scaleLineHeight;
$thumbColour: $primary;
$thumbColourActive: mix(white, $primary, 25%);
$thumbActiveRise: 0.1ex;
$thumbShadow: 0 0.2ex 0.2ex 0 rgba(black, 0.14), 0 0.3ex 0.1ex -0.2ex rgba(black, 0.12), 0 0.1ex 0.5ex 0 rgba(black, 0.2);
$thumbShadowActive: 0 0.4ex 0.6ex 0 rgba(black, 0.14), 0 0.3ex 0.3ex -0.2ex rgba(black, 0.12), 0 0.1ex 0.7ex 0 rgba(black, 0.2);
$trackHeight: $scaleLineHeight / 3;
$trackBg: white;
$trackBorderRadius: $trackHeight / 2;
$trackBorder: $scaleColour solid thin;
$trackLowerBg: linear-gradient(
    to right,
    #{$trackBg},
    #{$pink},
    #{$thumbColour}
);


.rangeInput--dragging
{
    cursor: grabbing;

    *
    {
        cursor: inherit !important;
    }
}


.rangeInput__scale
{
    position: relative;
    user-select: none;
}



.rangeInput__scaleItems
{
    display: flex;
}

.rangeInput__scaleItem
{
    flex: 1;
    text-align: center;
    font-size: $scaleLabelSize;
    height: $scaleLineHeight + $scaleLabelSizeChosen;
    line-height: 1;
    position: relative;

    .rangeInput--enabled &
    {
        cursor: pointer;
    }
}

.rangeInput__scaleItem:hover
{
    .rangeInput--enabled &
    {
        color: $scaleLabelColourActive;
    }
}

.rangeInput__scaleItem::before
{
    content: '';
    display: block;
    margin:
        0
        auto
        $contentVerticalPadding / 4;
    height: $scaleLineHeight;
    width: 0;
    border-left: $scaleColour solid thin;
}

.rangeInput__scaleItem--invisible::before
{
    border-left: none;
}

.rangeInput__scaleItem:hover::before
{
    .rangeInput--enabled &
    {
        border-left-color: $scaleLabelColourActive;
    }
}

.rangeInput__scaleItemValue
{
    position: absolute;
    transform: translateX(-50%);
    transform-origin: top center;
    transition: transform $transition;
    text-align: center;

    .rangeInput__scaleItem[aria-selected=true] &
    {
        transform: translateX(-50%) scale(1.33);
    }

    .rangeInput__scaleItem:first-child &
    {
        transform-origin: top left;
        text-align: left;
    }

    .rangeInput__scaleItem:last-child &
    {
        transform-origin: top right;
        text-align: right;
    }
}



.rangeInput__track
{
    pointer-events: none;
    display: flex;
    position: absolute;
    top: ($scaleLineHeight - $trackHeight) / 2;
    left: 0;
    right: 0;
    height: $trackHeight;
    border-radius: $trackBorderRadius;
    border: $trackBorder;
    background: $trackBg;
}



.rangeInput__trackLower
{
    border-top-left-radius: $trackBorderRadius;
    border-bottom-left-radius: $trackBorderRadius;
    background: $trackLowerBg;
    transition: width $transition;

    .rangeInput--dragging &
    {
        transition-duration: $immediateTransitionDuration;
    }
}



.rangeInput__thumb
{
    width: 0;
    height: $trackHeight;
    position: relative;
    user-select: none;

    padding: 0;
    border: 0;

    .rangeInput--disabled &
    {
        cursor: initial;
    }

    .rangeInput--enabled &
    {
        cursor: grab;
    }
}

.rangeInput__thumb::before
{
    pointer-events: all;
    content: '';
    display: block;
    position: absolute;
    top: -1 * ($thumbSize - $trackHeight) / 2;
    left: -1 * $thumbSize / 2;
    width: $thumbSize;
    height: $thumbSize;
    border-radius: 50%;
    background-color: $thumbColour;
    box-shadow: $thumbShadow;
    transition: $transition;
    transition-property: border-color box-shadow;
}

// Visually hidden, but covers whole parent
.rangeInput__inputWrapper
{
    overflow: hidden;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    clip: rect(0, 0, 0, 0);
}

.rangeInput__input
{
    margin: 0;
    padding: 0 !important;
    width: 100%;
    height: 100%;
}

.rangeInput:focus,
.rangeInput__thumb:focus
{
    /* we use background-color and box-shadow to indicate focus */
    outline: none;
}

.rangeInput:focus .rangeInput__thumb::before,
.rangeInput__inputWrapper:focus + .rangeInput__thumb::before,
.rangeInput__thumb:focus::before
{
    background-color: $thumbColourActive;
    box-shadow: $thumbShadowActive;
    transform: translateY(#{ $thumbActiveRise * -1 });
}


/* VERTICAL */
.rangeInput[data-vertical="true"]
{
    @supports (--a: calc(1em))
    {
        --width: 5rem;
        --labelWidth: 5rem;
        --height: 30rem;
        --padding: 1rem;

        width: calc(var(--width) + var(--labelWidth) + 2 * var(--padding));
        height: calc(var(--height) + 2 * var(--padding));
        overflow: hidden;
        padding: var(--padding);

        .rangeInput__scale
        {
            width: var(--height);
            height: var(--width);
            margin-top: var(--labelWidth);
            transform:
                rotate(270deg)
                translateX(calc(-100% + var(--width) / 2 + var(--labelWidth)))
                translateY(calc(var(--width) / 2 + var(--labelWidth)));
            transform-origin: left center;
        }

        .rangeInput__scaleItemValue
        {
            height: auto;
        }

        .rangeInput__scaleItemValue
        {
            position: absolute;
            top: 0;
            right: 0;
            width: calc(var(--labelWidth));
            transform:
                rotate(90deg)
                translateX(-0.5rem);
            transform-origin: top right;
            text-align: right;
        }
    }
}
