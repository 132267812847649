html {
    height: 100%;
}

body {
    font-family: 'Netto', sans-serif;
    color: $font-color;
    overflow-x: hidden;
    height: 100%;
}

main{
    background-color: $white;
}

section {
    padding: 3.5rem 0;

    @include media-breakpoint-up(lg) {
        padding: 4rem 0;
    }

    &.border {
        border-bottom: 2px solid $light-blue;
    }
}

.container {
    @include media-breakpoint-up(sm) {
        padding: 0;
    }
}

.no-gutters {
    @include media-breakpoint-up(sm) {
        margin-right: 0;
        margin-left: 0;
    }
}

.clearfix {
    clear: both;
}

.h100 {
    height: 100vh;
}

.h100-timeline {
    height: calc(100vh - 70px);
}

.white-box{
    background: $white;
    box-shadow: 0px 4px 6px rgba($black, 0.1);
    border-radius: $borderRadius;
    padding: 1rem;
    margin: 0 0 1rem 0;
    font-size: 18px;
    color: $font-color;
    text-decoration: none !important;

    @include media-breakpoint-up(md) {
        padding: 2rem;
    }

    &.h-100{
        height: calc(100% - 1rem) !important;
    }
}

//preloader
.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($light-grey, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $zIndexLoader;

    &.no-fixed {
        position: absolute;
    }

    img {
        flex: 0 1 auto;
    }

    .spinner-border {
        border: 0.25rem solid $primary;
        border-right-color: transparent;
    }
}

//placeholder
.placeholder {
    background: $light-grey;
    background-image: linear-gradient(
        to right,
        $light-grey 0%,
        darken($light-grey, 4%) 20%,
        $light-grey 40%,
        $light-grey 100%
    );
    background-repeat: no-repeat;
    background-size: 960px 480px;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;

    color: transparent;

    &:before {
        display: none;
    }
}

@keyframes placeholderShimmer {
    0% {
        background-position: -480px 0;
    }

    100% {
        background-position: 960px 0;
    }
}

.modal-lg, .modal-xl {
    @include media-breakpoint-up(md){
        max-width: 720px;
    }

    @include media-breakpoint-up(lg){
        max-width: 940px;
    }

    @include media-breakpoint-up(xl){
        max-width: 1140px;
    }
    
}

.modal-content {
    border-radius: rem-calc(10);
    border: none;

    .modal-header {
        padding: 1rem 1.5rem 0 1.5rem;
        border: none;
    }

    .modal-body {
        position: relative;
        padding: 3rem 0 1rem 0;

        @include media-breakpoint-up(sm){
            padding: 3rem 1rem 1rem 1rem;
        }

        @include media-breakpoint-up(md){
            padding: 1.5rem 3rem 1.5rem 1.5rem;
        }

        .close{
            position: absolute;
            top: 0.75rem;
            right: 0.75rem;

            @include media-breakpoint-up(md){
                top: 1.25rem;
                right: 0.75rem;
            }
        }

        h3 {
            font-size: rem-calc(18);
            color: $dark-grey;
        }

        p {
            font-size: rem-calc(15);
            color: $grey;
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .fast-code{
            color: rgba($purple, 0.5);

            strong{
                color: $purple;
            }
        }

        .diet-phase{
            display: inline-block;
            font-size: rem-calc(14);
            padding: 0.25rem 1rem;
            border-radius: rem-calc(10);
            border: 1px solid;
            background: $white;

            &.start, &.exit{
                border-color: $orange-yellow;
                color: $orange-yellow;
            }

            &.fast{
                border-color: $purple;
                color: $purple;
            }

            &.health{
                border-color: $green;
                color: $green;
            }

            &.share-button{
                border-color: $purple;
                background: $purple;
                color: $white;
                min-width: auto;
                height: auto;
                background-image: url(../assets/icons/share_icon.svg);
                background-repeat: no-repeat;
                background-position: left 0.25rem center;
                background-size: 2rem;
                padding-left: 2.25rem;
            }
        }
    }

    .modal-footer {
        justify-content: center;
        border-top: 0;
        padding: 0 1.5rem 2rem 1.5rem;
    }
}

.svg-inline {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
    max-width: 1.5rem; // ie11 hack
}

.SVGInline {
    svg {
        font-size: inherit;
        height: 1em;
        max-width: 1.5rem; // ie11 hack
        vertical-align: -0.125em;
    }

    &.auto-height {
        svg {
            height: auto;
        }
    }
}

.stay-together {
    position: absolute;
    top: 0;
    left: 0;
}

.full-height {
    height: 100vh;
}

.print-table {
    margin: 2rem auto 0 auto;

    @media print {
        margin: auto;
    }

    thead {
        @media print {
            display: table-header-group;
        }
    }

    tfoot {
        @media print {
            display: table-footer-group;
        }
    }
}
