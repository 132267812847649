$jumpHeight: 1em;
$duration: 2s;
$itemsCount: 4;
$bounceTimeFrac: 0.5;
$horizontalSpacing: 4%;

// We need these widths to know how wide they are relative to each other
$beetrootImageWidth: 83px;
$carrotImageWidth: 45px;
$broccoliImageWidth: 168px;
$appleImageWidth: 116px;
$allImagesWidth: $beetrootImageWidth + $carrotImageWidth + $broccoliImageWidth + $appleImageWidth;

.longInlineLoader
{
    padding-top: $jumpHeight;
    white-space: nowrap;
    margin: 0 auto;
    max-width: 140px;
}

.longInlineLoader__produce
{
    display: inline-block;
    vertical-align: bottom;
    margin: 1em $horizontalSpacing;
    animation: longInlineLoader__bounce infinite #{$duration};
    transform-origin: bottom center;
}

.longInlineLoader__produce--beetroot
{
    animation-delay: $duration * $bounceTimeFrac / $itemsCount * 1;
    max-width: (100% - $itemsCount * $horizontalSpacing * 2) * $beetrootImageWidth / $allImagesWidth;
}

.longInlineLoader__produce--carrot
{
    animation-delay: $duration * $bounceTimeFrac / $itemsCount * 2;
    max-width: (100% - $itemsCount * $horizontalSpacing * 2) * $carrotImageWidth / $allImagesWidth;
}

.longInlineLoader__produce--broccoli
{
    animation-delay: $duration * $bounceTimeFrac / $itemsCount * 3;
    max-width: (100% - $itemsCount * $horizontalSpacing * 2) * $broccoliImageWidth / $allImagesWidth;
}

.longInlineLoader__produce--apple
{
    animation-delay: $duration * $bounceTimeFrac / $itemsCount * 4;
    max-width: (100% - $itemsCount * $horizontalSpacing * 2) * $appleImageWidth / $allImagesWidth;
}

@keyframes longInlineLoader__bounce
{
    /* stand still */
    0%,
    #{100% - 100% * $bounceTimeFrac},
    100%
    {
        animation-timing-function: linear;
        transform: none;
    }

    /* spring into action */
    #{100% * $bounceTimeFrac * 0.2},
    #{100% * $bounceTimeFrac * 0.35}
    {
        transform: scaleY(0.75) scaleX(1.2);
    }

    /* jump */
    #{100% * $bounceTimeFrac * 0.5}
    {
        transform: translateY(#{-1 * $jumpHeight}) scaleX(0.85);
        /* easing for landing */
        animation-timing-function: cubic-bezier(0, 0, 1, -0.5);
    }

    /* land */
    #{100% * $bounceTimeFrac * 0.8}
    {
        transform: scaleY(0.9) scaleX(1.1);
    }
}
