.chart {
    width: 100%;
    display: block;

    &.placeholder::after {
        content: '';
        display: block;
        padding-top: 50%;
    }
}
