.page-template-activities {
    header {
        padding: 0 0 2rem 0;

        @include media-breakpoint-up(md) {
            padding: 2rem 0;
        }

        h1 {
            font-size: 3rem;
            font-weight: 900;
        }
    }

    header.header-progress {
        padding: 0 0 2rem 0;

        h1 {
            font-size: 3rem;
        }

        .progress {
            border-radius: 0;
            margin: 0;

            @include media-breakpoint-up(md) {
                margin: 0 0 2rem 0;
            }

            .progress-bar {
                background: linear-gradient(to right, $pink 0%, $blue 100%);
            }
        }
    }

    h2 {
        font-size: 2rem;
        margin: 0 0 1rem 0;
    }

    .day {
        padding: 2rem 0;

        &:first-child {
            padding: 0 0 2rem 0;
        }

        h2 {
            font-size: 1.875rem;
            margin: 0;
        }

        h3 {
            font-size: 1.625rem;
            color: $black;
            margin: 0 0 1rem 0;
        }

        hr {
            margin: 2rem 0;
        }

        .activity-icon {
            margin: 0 0 0 0.625rem;
            cursor: pointer;

            img {
                width: 1.875rem;
            }
        }

        .periodic-icon {
            width: 1.875rem;
            margin: 0 0.625rem 0 0;
        }

        .activities-list {
            font-size: 1.5rem;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 1rem 0;
            }
        }
    }
}
