.page-template-settings {

    main{
        background: $light-grey;
    }

    header {
        padding: 2rem 0 0 0;
    }
}
