.authConfirm__appLogotypes
{
    padding: 1em 0;
    margin-left: -5rem;
    margin-right: -5rem;
}

.authConfirm__appLogo--hideOnDesktop
{
    opacity: 0.3;

    @include media-breakpoint-up(sm) {
        visibility: hidden;
    }
}

.authConfirm__apStoreLinks
{
    a
    {
        margin: 0 0.5rem;
        display: inline-block;

        img
        {
            max-width: 100%;
        }
    }

    .avalibility{
        opacity: 0.3;
        font-size: rem-calc(12);
        margin-right: 0;
        margin-bottom: 1rem;
        display: inline-block;
    }

    .avalibility{
        opacity: 0.3;
        font-size: rem-calc(12);
        margin-right: 0;
        margin-bottom: 1rem;
        display: inline-block;
    }
}
