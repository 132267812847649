.floating-action-button {
    $colour: $primary;
    $activeColour: mix(white, $colour, 25%);
    $size: 5rem;
    $margin: 2rem;
    --margin: #{$margin};

    position: fixed;
    right: $margin;
    bottom: $margin;
    right: var(--margin);
    bottom: var(--margin);
    z-index: $zIndexFloatingACtion;
    width: $size;
    height: $size;
    background-color: $colour;
    border-radius: 50%;

    // Shadows more or less from
    // https://material.io/components/buttons-floating-action-button/
    box-shadow:
        0px 3px  5px -1px rgba(black, 0.2),
        0px 6px 10px  0px rgba(black, 0.14),
        0px 1px 18px  0px rgba(black, 0.12);
    transition: box-shadow 15ms linear, background-color 15ms linear;

    @include media-breakpoint-up(md) {
        --margin: 5vmin;
    }

    &,
    &:hover {
        color: white;
    }

    &:hover,
    &:focus {
        background-color: $activeColour;
        box-shadow:
            0px 5px  5px -3px rgba(black, 0.2),
            0px 8px 10px  1px rgba(black, 0.14),
            0px 3px 14px  2px rgba(black, 0.12);
    }

    &:active {
        background-color: $activeColour;
        box-shadow:
            0px  7px  8px -4px rgba(black, 0.2),
            0px 12px 17px  2px rgba(black, 0.14),
            0px  5px 22px  4px rgba(black, 0.12);
    }

    svg {
        display: block;
        margin: 25% auto 0;
        width: 50%;
        height: 50%;
    }
}

