.page-template-diet-settings {
    main {
        background-color: $light-grey;
        padding: 0;
        min-height: 100vh;

        @include media-breakpoint-up(sm) {
            padding: 4rem 0;
            min-height: auto;
        }

        .diet-settings-bottom-push{
            height: rem-calc(98);
    
            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

        .full-screen {
            display: flex;
            width: 100%;
            min-height: 100vh;
            flex-direction: column;
    
            @include media-breakpoint-up(sm) {
                min-height: auto;
            }
    
            &.container{
                min-height: calc(100vh - 3rem);
            }
        }
    
        .full-screen2 {
            min-height: calc(100vh - 50px - 3rem);
            display: flex;
            margin-top: rem-calc(-98);
    
            @include media-breakpoint-up(sm) {
                min-height: auto;
                margin-top: 0;
            }
        }
    
        .full-screen3 {
            margin-top: rem-calc(-98);
            min-height: calc(100vh - 98px - 52px);
            display: flex;
    
            @include media-breakpoint-up(sm) {
                min-height: auto;
                margin-top: 0;
            }
        }

        .action-buttons{
            position: sticky;
            top: calc(100vh - 98px);
            z-index: 10;
    
            width: 100%;
            left: 0;
            padding: 2rem 0 1rem 0;
            background-image: linear-gradient(
                to bottom,
                rgba($light-grey, 0) 0%,
                $light-grey 25%,
                $light-grey 100%
            );
    
            @include media-breakpoint-up(sm) {
                display: none;
            }
        }
    
        .action-buttons2{
            display: none;
            
            @include media-breakpoint-up(sm) {
                display: block;
                position: relative;
                top: initial;
                padding: 0;
                background-image: none;
                margin-top: 2rem;
            }
        }
    
        .logo{
            max-width: rem-calc(200);
        }
    
        h1 {
            display: none;
    
            @include media-breakpoint-up(sm) {
                display: block;
            }
        }
    
        p {
            font-size: rem-calc(20);
            color: $dark-grey;   
        }

        ul{
            font-size: 1rem;
            padding: 0;
            list-style: none;
        
            li {
                position: relative;
                padding: 0 0 0 1rem;
        
                &:before {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: 0;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: $primary;
                }
            }
        }
    
        .progress {
            border-radius: rem-calc(10);
            margin: 1rem 0 2rem 0;
            background: rgba($primary, 0.15);
            height: rem-calc(4);
    
            .progress-bar {
                background: $primary;
                border-radius: rem-calc(10);
            }
        }
    
        .hide {
            opacity: 0;
        }

        .form-group{
            label{
                color: $black;
                font-size: 1.25rem;
                display: block;
                text-align: left;
        
                sup{
                    color: $red
                }
            }
            .form-control{
                padding: 0;
                border-radius: $borderRadius;
                background: $white;
                border: 1px solid $input-border;
                height: auto;
        
                &.is-invalid{
                    border: 1px solid $primary;
                }
            
                input{
                    border: none;
                    background: none;
                    padding: 0.5rem 1rem;
        
                    &:disabled{
                        cursor: not-allowed;
                    }
                }
        
                textarea{
                    border: none;
                    background: none;
                    padding: 0.5rem 1rem;
        
                    &:disabled{
                        cursor: not-allowed;
                    }
                }
        
                .input-group-text {
                    background: none;
                    border: none;
                }
        
                .input-placeholder{
                    flex: 1 1 auto;
                    padding: 0.5rem 1rem;
                }
            }
        
            .invalid-feedback{
                color: $primary;
        
                li{
                    list-style: none;
                }
            }
        
            svg,
            .SVGInline {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                padding: 0.75rem;
                pointer-events: none;
                max-width: 3.5rem;
                z-index: 3;
            }
        
            &.is-invalid{
                .invalid-feedback{
                    display: block;
                }
            }
        }

        .ruler-slider {
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: 2rem;
            padding: 1rem 0 0 0;
            text-align: center;

           
            background: $white;
            border-top: 1px solid $input-border;
            border-bottom: 1px solid $input-border;

            @include media-breakpoint-up(sm) {
                margin-left: 0;
                margin-right: 0;
                border-radius: $borderRadius;
                border: 1px solid $input-border;
            }
        
            h3 {
                color: $grey;
                font-size: rem-calc(24);
                margin-bottom: 1rem;
        
                .big-number {
                    font-size: rem-calc(30);
                    color: $primary;
                    font-weight: bold;
                    vertical-align: baseline;
                }
            }
        
            .slide-ruler_box_1Le {
                &::before {
                    background-color: $primary !important;
                }
            }
        }
    }
    
    label,
    .label {
        width: 100%;
        font-size: rem-calc(20);
        font-weight: 400;
        color: $dark-grey;
        margin-bottom: 1rem;
    
        &.form-check-label,
        &.form-switch-label {
            position: relative;
            background: $white;
            box-shadow: 0px 4px 8px rgba($black, 0.1);
            border-radius: $borderRadius;
            margin: 0 0 rem-calc(14) 0;
            padding: 1rem 3.5rem 1rem 1rem;
    
            color: $font-color;
            font-size: rem-calc(16) !important;
            font-weight: 400;
    
            &::before {
                content: '';
                position: absolute;
                left: 1rem;
                bottom: 0;
                width: calc(100% - 2rem);
                height: 1rem;
                opacity: 0;
                border-radius: rem-calc(10);
            }
    
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                margin-top: rem-calc(-15);
                right: 1rem;
                width: rem-calc(30);
                height: rem-calc(30);
                background: $white;
                border-radius: rem-calc(15);
                background-image: url(../../assets/icons/check.svg);
                background-position: top 5px center;
                background-repeat: no-repeat;
                opacity: 0;
            }
    
            strong {
                color: $primary;
                font-weight: bold;
                font-size: rem-calc(18);
            }
    
            &.active {
                background: linear-gradient(
                    to right,
                    $primary 0%,
                    $primary 100%
                );
                color: $very-light-orange;;
    
                strong {
                    color: $white;
                }
    
                &::before {
                    opacity: 1;
                }
    
                &::after {
                    opacity: 1;
                }
            }
    
            &.rounded {
                padding: 0.30rem 0;
                text-align: center;
                margin-right: 0.5rem;
                width: 34px;
                border-radius: rem-calc(20) !important;
    
                strong {
                    font-size: rem-calc(16);
                }
    
                &::before {
                    border-radius: rem-calc(20);
                }
    
                &::after {
                    opacity: 0;
                }
    
                @include media-breakpoint-up(sm) {
                    padding: 0.5rem 0;
                    width: 40px;
                }
            }
        }

        &.custom-control-label{
            font-size: rem-calc(14);
            color: $secondary;
        }
    
        &.form-switch-label {
            padding: 0;
        }
    
        &.is-invalid {
            padding: 1rem 0 1rem 2.5rem !important;
        }
    
        sup {
            color: $red;
        }
    }
    
    .not-label {
        color: inherit;
        font-size: 1em;
        text-align: inherit;
        margin: initial;
        font-weight: inherit;
    }
    
    .form-check {
        padding: 0;
    
        input {
            opacity: 0;
        }
    
        .checkmark {
            display: none;
        }
    
        .form-control.is-invalid {
            padding: 0;
            border: none;
            background-image: none;
        }
    }

    .alert{
        h2{
            font-size: 1.5rem;
            margin-bottom: 1rem;
            color: $primary;
        }
        
        p{
            font-style: inherit;
            font-size: 1rem;
            color: inherit;
        }
    }
    
    .invalid-feedback {
        margin-top: 0.5rem;
        color: $primary;
        font-size: 100%;
        font-size: rem-calc(16);
    
        li {
            list-style: none;
        }
    }
    
    // disable webkit autofill yellow bg
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px $light-grey inset;
        -webkit-box-shadow: 0 0 0 30px $light-grey inset;
    }
}
