.btn{
    font-family: 'Netto', sans-serif;
    &.btn-primary{
        position: relative;
        background: $primary;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        color: $white;
        border: 2px solid transparent;
        border-radius: $borderRadius;
        padding: 6px 30px 6px 20px;
        transition: all 0.3s;
        // text-transform: uppercase;
        min-width: 152px;

        &::after{
            content: '';
            position: absolute;
            width: 12px;
            height: 100%;
            transition: all 0.3s;
            right: 10px;
            opacity: 1;
            top: 0;
            background-image: url('../assets/icons/chevron-right-white.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        &:active, &:hover{
            color: $white;
            background: #EC6401;
        }

        &.no-arrow{
            padding: 6px 20px;

            &::after{
                display: none;
            }

            &:hover{
                padding: 6px 20px;
            }
        }
    }

    &.btn-outline-primary{
        background: transparent;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        color: $primary;
        border: 2px solid $primary;
        border-radius: $borderRadius;
        padding: 6px 20px;
        text-transform: uppercase;

        &:active{
            background-color: transparent !important;
            border-color: $primary !important;
            color: $primary !important;
        }

        &.active{
            color: $white !important;
        }
    }

    &.btn-white{
        background: $white;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.5;
        color: $primary;
        border: 2px solid transparent;
        border-radius: $borderRadius;
        padding: 6px 20px;
    }

    &.btn-outline-white{
        background: transparent;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        color: $white;
        border: 2px solid $white;
        border-radius: $borderRadius;
        padding: 6px 20px;

        &:active{
            background-color: transparent !important;
            border-color: $white !important;
            color: $white !important;
        }
    }

    &.btn-social{
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        border-radius: 20px;
        padding: 6px 10px 6px 36px;
        display: block;
        margin: 0.5rem auto;
        // width: 18rem;
        font-family: Arial, Helvetica, sans-serif;
        
    }

    &.facebook{
        background: #4267b2;
        color: $white;
        border: 1px solid #4267b2;
        background-image: url(../assets/icons/button_facebook_logo.svg);
        background-position: left 10px center;
        background-repeat: no-repeat;
    }

    &.google{
        background: $white;
        color: $black;
        border: 1px solid $black; 
        background-image: url(../assets/icons/button_google_logo.svg);
        background-position: left 10px center;
        background-repeat: no-repeat;
    }

    &.apple{
        background: $black;
        color: $white;
        border: 1px solid $black;
        background-image: url(../assets/icons/button_apple_logo.svg);
        background-position: left 10px center;
        background-repeat: no-repeat;
    }

    &.btn-arrow{
        position: relative;
        padding: 0;
        color: $black;
        background-color: $white;
        font-weight: 400;
        border-radius: $border-radius;
        text-align: left;
        font-size: 16px;

        .SVGInline {
            float: right;
        }
    }

    &.btn-placeholder {
        position: relative;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.5;
        border: 0;
        border-radius: $borderRadius;
        padding: 6px 20px;
        transition: all 0.3s;

        background: $light-grey;
        background-image: linear-gradient(
            to right,
            $light-grey 0%,
            darken($light-grey, 4%) 20%,
            $light-grey 40%,
            $light-grey 100%
        );
        background-repeat: no-repeat;
        background-size: 960px 480px;

        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeholderShimmer;
        animation-timing-function: linear;

        color: transparent;

        &:after {
            display: none;
        }
    }

    &:focus {
        box-shadow: none !important;
    }
}
