.page-template-quiz{
	header{
        padding: 0 0 2rem 0;
        
        @include media-breakpoint-up(md){
			padding: 2rem 0;
		}

		h1{
			font-size: 3rem;
			//margin-bottom: 0;
			text-align: center;
		}
    }
    
    .blockquote{
        position: relative;
        margin: 0 0 3rem 3.5rem;
        padding-left: 1rem;
        border-left: 2px solid $blue;

        &:before{
            content: '\201D';
            position: absolute;
            top: -2rem;
            left: -3.5rem;
            color: $blue;
            font-size: 6.25rem;
        }
    }

    h2{
        font-size: 2rem;
        margin-bottom: 0.5rem;
    }

    h3{
        font-size: 1.75rem;

        .big-number {
            font-size: 3.75rem;
            line-height: 1;
        }
    }

    .quiz-intro__numbers{
        padding: 3.5rem 0;
        background: $blue;
        color: $white;
    }

    .btn{
		margin-top: 1rem;
	}
}