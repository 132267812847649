footer{
    background: $white;
	position: relative; 
    padding: 3rem 0 1.5rem 0 !important;
	border-top: 1px solid $light-grey;
	
	
	@include media-breakpoint-up(sm) {
		padding: 5rem 0;
	}

	.nav-link{
		font-size: 1rem;
		color: $font-color;
		padding: 0 0 1rem 0;

		&:hover{
			text-decoration: underline;
		}
	}

	.logo{
		margin: 0 1rem 0 0;
	}

	.dropdown-arrow {
		color: $primary;
		display: inline-block;
		transition: all 0.3s ease-in-out;

		&.open {
			transform: rotate(180deg);
		}
	}

	.avalibility{
		opacity: 0.3;
		font-size: rem-calc(12);
		margin-right: 0;
		margin-bottom: 1rem;
		display: inline-block;
	}

	.social-link{
		display: inline-block;
		background-color: $grey2;
		border-radius: 50%;
		margin: 0 1rem 1rem 0;
		transition: background-color 0.3s ease-in-out;

		&:hover{
			background-color: $primary;
		}
	}

	.oxy-color-separator{
		height: 28px;
		background-image: url('../assets/images/footer_color_separator.png');
		background-repeat: repeat-x;
		background-position: bottom;
	}

	p.small{
		font-size: 14px;
	}
}