.page-template-workouts {
    header {
        padding: 2rem 0 0 0;

        h1 {
            font-size: 3rem;
            font-weight: 900;
        }
    }
}
