.page-template-shopping-list {
    
    .full-screen {
        display: flex;
        width: 100%;
        min-height: calc(100vh - 5.5rem);
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            min-height: auto;
        }

        &.container{
            min-height: calc(100vh - 3rem);
        }
    }
    
    header {
        padding: 2rem 0 0 0;
    }

    section {
        padding-top: 0;
    }

    h3{
        font-size: 24px;
        font-weight: bold;
    }

    .products-category {
        margin-bottom: 2rem;

        h3{
            padding-bottom: 0.5rem;
            border-bottom: 1px solid $light-grey2;
        }
    }

    .ingredients {
        list-style: none;
        margin: 0 0 1rem 0;
        padding: 0;

        li > button {
            display: flex;
            justify-content: space-between;
            position: relative;
            margin: 1rem 0;
            padding: 0 0 0 2rem;
            cursor: pointer;
            width: 100%;
            max-height: 7rem;
            transition: all 0.3s;
            color: $font-color;
            text-align: left;
            font-size: rem-calc(18);

            background: none;
            border: none;
            appearance: none;
            outline: none;

            .float-right {
                float: none;
                white-space: nowrap;
            }

            &:before {
                content: '';
                position: absolute;
                top: rem-calc(5);
                left: 0;
                width: 1rem;
                height: 1rem;
                border-radius: 20%;
                border: 1px solid $primary;
                transition: background-color 0.3s ease-in-out;
                color: $black;
                background-repeat: no-repeat;
                background-position: center;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0.75rem;
                left: 2rem;
                width: 0;
                height: 1px;
                background: rgba($primary, 0.5);
                transition: width 0.3s ease-in-out;
            }

            &.done {
                &:before {
                    content: '';
                    border: none;
                    background-image: url(../../assets/icons/checkbox.svg);
                    border-color: rgba($primary, 0.5);
                }

                color: rgba($primary, 0.5);

                &:after {
                    content: '';
                    width: calc(100% - 2.5rem);
                }
            }

            &.hide-down {
                transform: translateY(5rem);
                opacity: 0;
                margin: 0 0 -1rem 0;
                max-height: 0;
            }

            &.hide-up {
                transform: translateY(-7rem);
                opacity: 0;
                margin: -1rem 0 0 0;
                max-height: 0;
            }

            &.show {
                animation-name: show;
                animation-duration: 0.3s;
                overflow: hidden;
            }
        }

        @keyframes show {
            0% {
                max-height: 7rem;
                opacity: 0;
            }

            1% {
                max-height: 0;
            }

            100% {
                max-height: 7rem;
                opacity: 1;
            }
        }
    }

    hr {
        margin: 2rem 0;
        border-top: 1px solid $primary;
    }

    .bought-section{
        background: $very-light-orange;
        border-radius: rem-calc(10);
        padding: 0;
    }

    .bought-button {
        margin: 0;
        color: $primary;
        padding: 0.75rem 1rem 0.5rem 1rem;
        position: relative;
        max-width: none;
        width: 100%;
        background: transparent;
        border: none;
        text-transform: inherit;
        text-align: inherit;
        font-weight: bold;
        font-size: 1rem;
        appearance: none;

        &:focus,
        &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
            border: none;
            background: transparent;
            color: $primary;
        }

        span.collapse-arrow {
            display: flex;
            align-items: center;
            position: absolute;
            right: 1rem;
            top: 0;
            color: $primary ;
            font-size: 1rem;
            height: 100%;
            transition: all 0.3s ease-in-out;

            &.open {
                transform: rotate(180deg);
            }
        }
    }

    .card {
        position: relative;
        border: none;
        background: transparent;
        margin: 0;

        .card-body {
            padding: 0 1rem;
            text-align: left;

            .ingredients {
                margin: 0;
            }

            p {
                margin: 0 0 rem-calc(10) 0;
                color: $secondary;
                font-style: italic;
                font-size: rem-calc(14);
            }
        }
    }
}
