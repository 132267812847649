.page-template-update {
    background-color: $light-grey2;

    .full-screen {
        display: flex;
        width: 100%;
        min-height: 100vh;
        flex-direction: row;
        align-items: center;
    }

    section{
        width: 100%;

        img{
            max-width: 15rem;
            margin: 0 auto 2rem auto;
        }

        .btn{
            margin-top: 1rem;
        }
    }
}
