.page-template-motivation-test {
    overflow-x: hidden;
    
    header {
        padding: 2rem 0 0 0;
    }  

    .progress{
        height: 2px;
    }

    label{
        color: $black;
        font-weight: bold;
        margin: 0 0 2rem 0;
    }

    .btn{
        width: 100%;

        @include media-breakpoint-up(md){
            width: auto;
        }

        &:disabled{
            display: none;
        }
    }

    .animated {
        animation-duration: 0.2s;
    }

    .fadeInLeft {
        animation-name: fadeInLeftNew;
    }

    @keyframes fadeInLeftNew {
        from {
            opacity: 0;
            transform: translate3d(-50%, 0, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeInRight {
        animation-name: fadeInRightNew;
    }

    @keyframes fadeInRightNew {
        from {
            opacity: 0;
            transform: translate3d(50%, 0, 0);
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    .fadeOutLeft {
        animation-name: fadeOutLeftNew;
    }

    @keyframes fadeOutLeftNew {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            transform: translate3d(-50%, 0, 0);
        }
    }

    .fadeOutRight {
        animation-name: fadeOutRightNew;
    }

    @keyframes fadeOutRightNew {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            transform: translate3d(50%, 0, 0);
        }
    }

    .hide {
        opacity: 0;
    }

}
