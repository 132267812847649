//colors
$black: #00142a;
$white: #fff;
$dark-grey: #454545;
$grey: #666666;
$grey3: #999999;
$light-grey: #F6F6F6; // input bg
$light-grey2: #E5E5E5; // input border
$orange: #FF8C00;
$very-light-orange: #FFF7ED;
$green: #00A447;
$light-green: #E5F6EC;
$red: #FF0000;
$purple: #AE0099;

$phaseDetoxColor: #AE0099;
$phaseBalanceColor: #00a447;
$phaseStepColor: #ff8c00;
$phaseFatBurningColor: #ff0000;
$phaseStabilisationColor: #00a447;

$promptRed: #ec1c24;
$promptGreen: #5a9347;
$promptPurple: #a20097;

$font-color: $grey;
$primary: $orange;
$secondary: $green;
$input-border: $light-grey2;
$input-bg: $light-grey;

$grey2: #C9C9C9;

$orange-yellow: #FF8C00;
$dark-orange: #d8461e;
$light-orange: #f05930;

$orange-opacity: #FFC1B11C;

$dark-green: #167135;
$very-light-green: #b2e299;
$green-opacity:#B8F4CA55;


$blue: #48b3e3;


$navy-blue: #044389;
$light-blue: #aeddfb;
$violet: #9872b5;

//env indicator
$devColor: $blue;
$stageColor: $green;
$prodColor: $red;
// In italics “v” from “dev” sticks out from the container tad too much
$italicDevCompensation: 1.1rem;

$zIndexEnvIndicator: 16777271;
$zIndexCacheMessage: 1001;
$zIndexFloatingACtion: 1001;
$zIndexFooter: 1000; // .fixed-top (main menu) has 1030
$zIndexMenu: 100;
$zIndexTimeline: 5;
$zIndexLoader: 1;

$borderRadius: 10px;
