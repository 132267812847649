@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.page-template-psychometric-test {
    main {
        // background-color: $light-grey;
        overflow-x: hidden;
        
        header {
            padding: 2rem 0 0 0;
        }  

        .progress{
            height: 2px;
        }

        .btn{
            width: 100%;

            @include media-breakpoint-up(md){
                width: auto;
            }

            &:disabled{
                display: none;
            }
        }

        .animated {
            animation-duration: 0.2s;
        }

        .fadeInLeft {
            animation-name: fadeInLeftNew;
        }

        @keyframes fadeInLeftNew {
            from {
                opacity: 0;
                transform: translate3d(-50%, 0, 0);
            }

            to {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        .fadeInRight {
            animation-name: fadeInRightNew;
        }

        @keyframes fadeInRightNew {
            from {
                opacity: 0;
                transform: translate3d(50%, 0, 0);
            }

            to {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        .fadeOutLeft {
            animation-name: fadeOutLeftNew;
        }

        @keyframes fadeOutLeftNew {
            from {
                opacity: 1;
            }

            to {
                opacity: 0;
                transform: translate3d(-50%, 0, 0);
            }
        }

        .fadeOutRight {
            animation-name: fadeOutRightNew;
        }

        @keyframes fadeOutRightNew {
            from {
                opacity: 1;
            }

            to {
                opacity: 0;
                transform: translate3d(50%, 0, 0);
            }
        }

        .hide {
            opacity: 0;
        }

        .social-proof{
            .slide{
                text-align: center;
                margin-bottom: 2rem;
                outline: none;
                transition: all 0.5s;
    
                @include media-breakpoint-up(md) {
                    margin-top: 4rem;
                }
        
                img{
                    display: block;
                    margin: 0 auto 1rem auto;
                    max-width: 60%;
                    transition: all 0.5s;
                }
        
                p{
                    font-size: 16px;
                    margin: 0 0 1rem 0;
                    color: $grey;
    
                    @include media-breakpoint-up(md) {
                        font-size: 13px;
                    }
                }
            }
        
            .slick-slider{
                margin: 0 -15px;
            }
        
            .slick-slide{
                padding: 0 15px;
            }
        
            .slick-dots{
                bottom: 0;
    
                li{
                    width: 12px;
                    height: 12px;
    
                    button{
                        padding: 0;
                        border-radius: 50%;
                        border: 1px solid $primary;
                        width: 12px;
                        height: 12px;
    
                        &::before{
                            display: none;
                        }
                    }
    
    
                    &.slick-active{
                        button {
                            background: $primary;
                        }
                    }
                }
            }
    
            .slick-center{
                @include media-breakpoint-up(md) {
                    margin-top: -4rem;
                    transition: all 0.5s;
                    img {
                        max-width: 80%;
                    }
                    p{
                        font-size: 19px;
                    }
                }
            }
        }
    }

    .form-group{
        label{
            color: $black;
            font-size: 1.25rem;
            display: block;
            text-align: left;
    
            sup{
                color: $red
            }
        }
        .form-control{
            padding: 0;
            border-radius: $borderRadius;
            background: $white;
            border: 1px solid $input-border;
            height: auto;
    
            &.is-invalid{
                border: 1px solid $primary;
            }
        
            input{
                border: none;
                background: none;
                padding: 0.5rem 1rem;
    
                &:disabled{
                    cursor: not-allowed;
                }
            }
    
            textarea{
                border: none;
                background: none;
                padding: 0.5rem 1rem;
    
                &:disabled{
                    cursor: not-allowed;
                }
            }
    
            .input-group-text {
                background: none;
                border: none;
            }
    
            .input-placeholder{
                flex: 1 1 auto;
                padding: 0.5rem 1rem;
            }
        }
    
        .invalid-feedback{
            color: $primary;
    
            li{
                list-style: none;
            }
        }
    
        svg,
        .SVGInline {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            padding: 0.75rem;
            pointer-events: none;
            max-width: 3.5rem;
            z-index: 3;
        }
    
        &.is-invalid{
            .invalid-feedback{
                display: block;
            }
        }
    }

    label,
    .label {
        width: 100%;
        font-size: rem-calc(20);
        font-weight: 400;
        color: $dark-grey;
        margin-bottom: 1rem;
    
        &.form-check-label,
        &.form-switch-label {
            position: relative;
            background: $white;
            box-shadow: 0px 4px 8px rgba($black, 0.1);
            border-radius: $borderRadius;
            margin: 0 0 rem-calc(14) 0;
            padding: 1rem 3.5rem 1rem 1rem;
    
            color: $font-color;
            font-size: rem-calc(16) !important;
            font-weight: 400;
    
            &::before {
                content: '';
                position: absolute;
                left: 1rem;
                bottom: 0;
                width: calc(100% - 2rem);
                height: 1rem;
                opacity: 0;
                border-radius: rem-calc(10);
            }
    
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                margin-top: rem-calc(-15);
                right: 1rem;
                width: rem-calc(30);
                height: rem-calc(30);
                background: $white;
                border-radius: rem-calc(15);
                background-image: url(../../assets/icons/check.svg);
                background-position: top 5px center;
                background-repeat: no-repeat;
                opacity: 0;
            }
    
            strong {
                color: $primary;
                font-weight: bold;
                font-size: rem-calc(18);
            }
    
            &.active {
                background: linear-gradient(
                    to right,
                    $primary 0%,
                    $primary 100%
                );
                color: $very-light-orange;;
    
                strong {
                    color: $white;
                }
    
                &::before {
                    opacity: 1;
                }
    
                &::after {
                    opacity: 1;
                }
            }
    
            &.rounded {
                padding: 0.30rem 0;
                text-align: center;
                margin-right: 0.5rem;
                width: 34px;
                border-radius: rem-calc(20) !important;
    
                strong {
                    font-size: rem-calc(16);
                }
    
                &::before {
                    border-radius: rem-calc(20);
                }
    
                &::after {
                    opacity: 0;
                }
    
                @include media-breakpoint-up(sm) {
                    padding: 0.5rem 0;
                    width: 40px;
                }
            }
        }

        &.custom-control-label{
            font-size: rem-calc(14);
            color: $secondary;
        }
    
        &.form-switch-label {
            padding: 0;
        }
    
        &.is-invalid {
            padding: 1rem 0 1rem 2.5rem !important;
        }
    
        sup {
            color: $red;
        }
    }

    .form-check {
        padding: 0;
    
        input {
            opacity: 0;
        }
    
        .checkmark {
            display: none;
        }
    
        .form-control.is-invalid {
            padding: 0;
            border: none;
            background-image: none;
        }
    }

    .bubble{
        background-image: url('../../assets/images/psychometric/bubble.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        font-size: 1.5rem;
        color: $black;
        text-align: center;
        padding: 7rem 9rem;

    }

    img.feature{
        max-width: 120px;
        height: auto;
        margin: 2rem 0;
    }

    aside {
        &.right {
            float: right;
            margin-left: 1rem;
            margin-bottom: 1rem;
        }

        &.left {
            float: left;
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        &.did-you-know{
            position: relative;
            border: 1px solid $light-grey2;
            border-radius: $borderRadius;
            padding: 2rem;
            margin: 2rem 0;
            
            
            &:before{
                content: '';
                position: relative;
                display: block;
                width: 6rem;
                height: 6rem;
                background-size: 6rem;
                background-image: url(../../assets/icons/bulb.svg);
                background-repeat: no-repeat;
                margin: 0 auto 1rem auto;

            }

            @include media-breakpoint-up(md) {
                display: flex;
                align-items: center;

                &:before{
                    flex: 0 0 auto;
                    margin: 0 2rem 0 0;
                }
            }

            p{
                font-size: 1.125rem;
                margin: 0;

                @include media-breakpoint-up(md) {
                    flex: 0 1 auto;
                }
            }
        }
    }

    .guide{
        display: inline-block;
        border: 1px solid $light-grey2;
        margin: 0 0 1rem 0;
    }

    .scroll-link{
        cursor: pointer;
       
        &.btn{
            color: $white;

            &:hover{
                color: $white;
            }
        }
    }

    .personality{
        display: block;
        max-width: 300px;
        margin: 0 auto;
    }

    p{
        line-height: 2;

        strong{
            color: $black;
        }
    }

    .primary-box{
        background: $primary;
        padding: 4rem;
        text-align: center;

        h2{
            color: $white;
        }
    }

    .rotate180deg{
        transform: rotateY(180deg);
    }

    .promoQuote {
        text-align: center;

        p{
            margin-top: -1rem;
            border: 2px solid $primary;
            border-radius: $borderRadius;
            padding: 2rem;
        }
       
    }
}
