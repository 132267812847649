.page-template-interventions {
    overflow-x: hidden;
    
    header {
        padding: 2rem 0 0 0;
    }

    .big-icon{
        max-height: 5rem;
        width: auto;
    }

    .interventions-list{
        margin: 0;
        padding: 0;
        list-style: none;

        li{
            &.UserInterventionHidden{
                opacity: 0.5;
            }

            &::after{
                content: "";
                height: 1rem;
                width: 1px;
                background: $light-grey2;
                margin: 0.5rem auto;
                display: block;

                @include media-breakpoint-up(md){
                    height: 2rem;
                    margin: 1rem auto;
                }
            }

            &:last-child{
                &::after{
                    display: none;
                }
            }

            a, .placeholder{
                display: block;
                border: 1px solid $light-grey2;
                color: $font-color;
                padding: 1rem;
                line-height: 1;
                min-height: 69px;
                font-weight: 600;
                
                &:hover{
                    text-decoration: none;
                }
            }

            .icon{
                max-width: 2rem;
                height: auto;
            }

            .SVGInline{
                color: lighten($grey, 40%);
                font-size: 2rem;

                &.done{
                    color: $green;
                }
            }
        }
    }

    .btn.disabled, .btn:disabled {
        display: none;
    }

    .MotivationIntervention{
        padding: 1rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        max-width: 45rem;
        margin: 0 auto;

        @include media-breakpoint-up(md){
            padding: 5rem;
        }

        p{
            font-size: 1rem;
        }

        ul, ol{
            padding: 0;
            margin: 0 0 1rem 1rem;
        }
    }

    .MotivationIntervention--prompt{

        @include media-breakpoint-up(md){
            min-height: 30rem;
        }
        
        p{
            font-size: 1.25rem;

            &:first-of-type{
                font-size: 2.25rem;
            }
        }
    }
    
    .MotivationIntervention--question
    {
        background-image: url('../../assets/images/interventions/backgrounds/sofa.svg');
        background-color: $promptPurple;
        
    }
    
    .MotivationIntervention--quote
    {
        background-image: url('../../assets/images/interventions/backgrounds/lightbulb.svg');
        background-color: $primary;
    }
    
    .MotivationIntervention--behavioral
    {
        background-image: url('../../assets/images/interventions/backgrounds/stairs-up.svg');
        background-color: $promptRed;
    }
    
    .MotivationIntervention--psychological
    {
        background-image: url('../../assets/images/interventions/backgrounds/outside.svg');
        background-color: $promptGreen;
    }

    .MotivationIntervention--revealed
    {
        border: 1px solid $light-grey2;
        background-image: none;
        background-color: $white;

        &::before{
            content: '';
            display: block;
            width: 2rem;
            height: 2rem;
            margin: 0 auto 2rem auto;
        }

        &::after{
            content: '';
            display: block;
            width: 3rem;
            height: 3rem;
            margin: 2rem auto 0 auto;
        }

        &.MotivationIntervention--quote::before{
            background-image: url('../../assets/icons/interventions/pin.svg');
        }

        &.MotivationIntervention--question::before{
            background-image: url('../../assets/icons/interventions/conversation.svg');
        }

        &.MotivationIntervention--behavioral::before{
            background-image: url('../../assets/icons/interventions/flag.svg');
        }

        &.MotivationIntervention--psychological::before{
            background-image: url('../../assets/icons/interventions/eye.svg');
        }

        &.MotivationIntervention--concluded::after{
            background-image: url('../../assets/icons/interventions/done-primary.svg');
        }
    }

    .MotivationIntervention--succeed
    {
        background-color: $promptGreen;
        color: $white;

        @include media-breakpoint-up(md){
            min-height: 30rem;
        }
    }

    .MotivationIntervention--hidden{
        &::after{
            display: none;
        }
    }

    .number{
        color: $white;
        font-size: 6rem;
        line-height: 1;
        font-weight: bold;
        text-align: center;

        @include media-breakpoint-up(md){
            text-align: left;
        }

        .SVGInline{
            svg{
                height: initial;
                max-width: 6rem;
            }
        }
    }

    .content{
        color: $white;
        font-size: 2.25rem;

        @include media-breakpoint-up(md){
            font-size: 2.25rem;
            padding-left: 7rem;
        }
    }

    .action{
        color: $white;
        text-align: center;
        margin-top: 2rem;

        @include media-breakpoint-up(md){
            text-align: right;
        }
    }

    blockquote {
        position: relative;
        font-style: italic;
        margin: 3rem 0 2rem 0;

        p{
            font-size: 1.5rem !important;
        }

        cite{
            display: block;
            font-style: normal;
            text-align: right;
            font-weight: 700;

            &:before{
                content: '—';
            }
        }
        
        &:before {
            content: '\201D';
            position: absolute;
            top: -2.5rem;
            left: 0rem;
            color: $primary;
            font-size: 6rem;
            line-height: 1;
            font-family: Arial, Helvetica, sans-serif;

            @include media-breakpoint-up(md){
                left: -2.5rem;
            }
        }
    }

    .progress{
        height: 2px;
    }

}
