.page-template-promo-code
{
    $padding: 1rem;

    header{
		padding:  0 0 2rem 0;

		@include media-breakpoint-up(md){
			padding: 2rem 0;
		}
    }

    .form-group
    {
        position: relative;
        border: dashed thick;
        padding: $padding;

        &::before
        {
            $height: $padding;

            content: '✂';
            position: absolute;
            top: -0.5 * $height;
            left: $padding;
            font-size: 4em;
            display: inline-block;
            vertical-align: center;
            line-height: $height;
            background-color: white;

            /* We’d prefer not to get colour emojis as the orientation
             * of the scissors is not consistent between fonts.
             * Fortunatelly non-colour ones are always horizontal
             */
            font-family:
                "Segoe UI Symbol",
                "Webdings",
                "Wingdings";

            @supports (text-shadow: 0 0 0 red)
            {
                $width: 2px;
                $color: white;

                background-color: transparent;
                text-shadow:
                    #{$width} #{$width} 0 #{$color},
                    #{$width} #{-1 * $width} 0 #{$color},
                    #{-1 * $width} #{$width} 0 #{$color},
                    #{-1 * $width} #{-1 * $width} 0 #{$color};
            }
        }
    }
}
