.page-template-guides {
    main{
        header {
            padding: 2rem 0;
        }
    
        .guide{
            display: inline-block;
            border: 1px solid $light-grey2;
            margin: 0 0 1rem 0;
        }
        
        p{
            line-height: 2;
        }
    }
}