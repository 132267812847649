.page-template-measurement {
    main{
        background: $light-grey;
    }

    header {
        padding: 2rem 0 0 0;
    }

    .btn {
        min-width: auto;
    }

    .box{
        background: $white;
        box-shadow: 0px 4px 6px rgba($black, 0.1);
        border-radius: $borderRadius;
        padding: 1rem;
        margin: 0 0 1rem 0;
        font-size: 18px;
        color: $font-color;
        text-decoration: none !important;

        img{
            width: 60px;
            height: 60px;
        }

        .SVGInline{
            color: $primary;
        }

        span.value{
            font-weight: bold;
            color: $black;
        }

        span.no-value{
            color: rgba($grey, 0.7);
            font-style: italic;
        }
    }

    a:hover{
        text-decoration: none;
    }

    table {
        font-size: 1rem;
        cursor: pointer;

        th {
            font-weight: normal;
        }

        a {
            //display: table-row;
            color: inherit;
        }

        .edit-icon {
            color: $secondary;
            font-size: 1rem;
        }

        .change-up {
            color: $primary;
        }

        .change-down {
            color: $green;
        }

        .no-change {
            color: $grey2;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .deleteMeasurment {
        padding: 0;
        color: $primary;
        text-align: center;
        display: block;
        margin: 1rem auto 0 auto;

        height: auto;
        max-width: none;
        background: none;
        border: none;
        text-transform: inherit;
        font-weight: inherit;
        appearance: none;

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }
}
