#main-menu {
    padding: 0;
    border-bottom: 1px solid $light-grey;
    z-index: 11;
    position: relative;
    
    .user-menu-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
    }

    nav.navbar {
        padding: 0 1rem;
        background: $white;
        transition: background-color 0.3s ease-in-out;
        border-bottom: none;

        a.btn,
        button {
            padding: 0 2rem;
            font-size: 1rem;
            height: 3rem;
        }

        .navbar-brand {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            margin: 0;

                @include media-breakpoint-up(lg) {
                    margin: 0 2rem 0 0;
                }

            img {
                width: 8rem;

                @include media-breakpoint-up(sm) {
                    width: 10rem;
                }
                
                @include media-breakpoint-up(lg) {
                    width: 12rem;
                }
            }
        }

        .dropdown {
            .dropdown-arrow {
                display: inline-block;
                transition: all 0.3s ease-in-out;
            }

            .dropdown-menu {
                font-size: 1.375rem;
                color: $black;

                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                padding: 0.75rem;

                .dropdown-header {
                    font-size: 1rem;
                    color: $primary;
                    padding: 0;
                }

                .dropdown-item {
                    padding: 0;
                    height: auto;
                    line-height: normal;
                    background: $white;
                    border-bottom: 1px solid $light-grey;

                    .nav-link{
                        padding: 0.75rem 0;
                    }
                }
            }

            &.show {
                .dropdown-arrow {
                    transform: rotate(180deg);
                }

                .dropdown-menu {
                    opacity: 1;
                }
            }
        }

        .navbar-toggler {
            padding: 0;
            outline: none;
            color: $black;
            transition: color 0.3s ease-in-out;
            border-radius: 0;
            font-weight: bold;

            .dropdown-arrow {
                color: $primary;
                display: inline-block;
                transition: all 0.3s ease-in-out;
            }
        }

        .dropdown-toggler {
            display: flex;
            align-items: center;
            background: none;
            padding: 0;
            margin-top: 0;
            color: $black;
            box-shadow: none;
            border-radius: 0;
            font-size: 1rem;
            font-weight: bold;
            height: 3rem;
            cursor: pointer;
            width: auto;
            border: none !important;
            min-width: auto;
            

            .dropdown-arrow {
                color: $primary;
                display: inline-block;
                transition: all 0.3s ease-in-out;

                &.open {
                    transform: rotate(180deg);
                }
            }
        }

        .nav-link {
            position: relative;
            font-size: 1rem;
            color: $black;
            white-space: nowrap;
            
            text-transform: uppercase;
            font-weight: bold;

            text-align: center;
            border-top: 1px solid $light-grey;

            &:after{
                content: '';
                position: absolute;
                bottom: -1px;
                left: 0;
                height: 1px;
                width: 100%;
                background: $primary;
                transition: transform 0.3s ease-in-out;
                transform: scaleX(0);
            }

            &:hover {
                background-color: rgba($light-grey, 0.5);
                border-top: 1px solid rgba($grey, 0.05);

                &:after{
                    transform: scaleX(1);
                }

                @include media-breakpoint-up(lg) {
                    background-color: transparent;
                }
            }

            &.active {
                color: $primary;

                &:after{
                    transform: scaleX(1);
                }
            }

            @include media-breakpoint-up(lg) {
                padding: 1.5rem 1rem;
                border-top: none;

                &:hover {
                    border-top: none;
                    color: $primary;
                }
            }
        }

        .user-menu-collapse {
            .nav-link {
                border-top: 1px solid rgba($grey, 0.1);

                &:hover {
                    background-color: rgba($light-grey, 0.5);
                    border-top: 1px solid rgba($grey, 0.05);
                }

                @include media-breakpoint-up(lg) {
                    border-top: none;

                    &:hover {
                        border-top: none;
                    }
                }
            }
        }

        .dropdown-toggler::after {
            background: transparent;
        }

        &.navbar-open {
            background-color: $white;

            @include media-breakpoint-up(lg) {
                background-color: $white;
            }

            .navbar-toggler {
                color: $black;
                background: transparent;

                .dropdown-arrow {
                    color: $primary;
                    transform: rotate(180deg);
                }
            }

            .dropdown-toggler {
                color: $black;
                background: transparent;

                .dropdown-arrow {
                    color: $primary;
                }
            }

            button {
                color: $primary;
                background: $white;
            }

            .nav-link {
                color: $black;

                border-top: 1px solid rgba($grey, 0.1);

                &:hover {
                    background-color: rgba($light-grey, 0.5);
                    border-top: 1px solid rgba($grey, 0.05);
                }

                @include media-breakpoint-up(lg) {
                    border-top: none;

                    &:hover {
                        border-top: none;
                    }
                }

                &:after{
                    content: '';
                    background: $primary;
                }

                &.active {
                    color: $primary;
                }
            }
        }
    }
}

.dropdown-item {
    padding: 0;

    a {
        display: block;
        width: 100%;
        padding: 0.25rem 1.5rem;
        font-size: 1.375rem;
        color: $navy-blue;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: $light-grey;
            color: $blue;
        }
    }
}
