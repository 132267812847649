.page-template-coupon{
	header{
		padding:  0 0 2rem 0;

		@include media-breakpoint-up(md){
			padding: 2rem 0;
		}
    }

	.collapse.show{
		display: inline;
	}

	.form-group{
		margin: 1rem 0;

		label{
			font-size: 1rem;
			color: $font-color;
			text-align: left;
		}
	}

	.card{
		overflow: hidden;
		border-radius: 0.5rem;
	}

	.card-header{
		font-size: 1.2rem;
		text-align: center;
		background: $primary;
		color: $white;
		border-bottom: 1px solid $light-grey;
	}

	.nav-tabs{
		border: none;
		background: $white;

		.nav-item{
			width: 50%;
			margin-bottom: 0;
			
			.nav-link{
				font-size: 1.2rem;
				text-align: center;
				color: $font-color;
				border-radius: 0;
				border: none;

				&.active{
					background: $primary;
					color: $white;
				}
			}
		}
	}
}