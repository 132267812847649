.page-template-cms-content {
    header {
        padding: 2rem 0;

        h1 {
            font-size: 3rem;
            font-weight: 900;
            margin-bottom: 0;
            text-align: center;
        }
    }

    h2 {
        font-size: 2rem;
        margin: 1.375rem 0 1rem 0;
        text-align: center;
    }

    h3 {
        font-size: 1.75rem;
        margin-bottom: 1rem;
        color: $black;
    }

    ol {
        font-size: 1.5rem;
        padding: 0 0 0 2rem;

        li {
            position: relative;
            padding: 0 0 1rem 1rem;
        }
    }

    .card {
        border: none;

        .card-body {
            padding: 0rem;
            font-size: 1.375rem;
            text-align: left;

            p {
                font-size: 1.375rem;
            }
        }
    }

    section.pt-0 {
        div {
            &:last-child {
                hr {
                    display: none;
                }
            }
        }
    }

    img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    }
}
