$answerColour: $primary;
$innerColour: #e5e5e5;
$outerColour: #555;
$labelColour: $black;

.MotivationInterventionHealthCircle
{
    stroke-width: 1px;
    stroke-linecap: round;
    fill: none;
}

.MotivationInterventionHealthCircle__ring
{
    stroke: $innerColour;
}

.MotivationInterventionHealthCircle__ring:last-child
{
    stroke: $outerColour;
}


.MotivationInterventionHealthCircle__areaBorder
{
    stroke: $innerColour;
}


.MotivationInterventionHealthCircle__areaLabel
{
    fill: $labelColour;
    text-anchor: middle;
    dominant-baseline: middle;
}


.MotivationInterventionHealthCircle__label
{
    fill: $labelColour;
    font-size: size(-1);
    text-anchor: middle;
}


.MotivationInterventionHealthCircle__answers
{
    stroke: $answerColour;
    stroke-width: 2.5px;
    fill: rgba($answerColour, 0.2);
}
