@font-face
{
    font-family: 'Netto';
    font-weight: 400;
    font-style: normal;
    src:
        local('☺︎'),
        url('../assets/fonts/nettopro/400.woff2') format('woff2'),
        url('../assets/fonts/nettopro/400.woff') format('woff');
}

@font-face
{
    font-family: 'Netto';
    font-weight: 300;
    font-style: normal;
    src:
        local('☺︎'),
        url('../assets/fonts/nettopro/300.woff2') format('woff2'),
        url('../assets/fonts/nettopro/300.woff') format('woff');
}

@font-face {
    font-family: 'Netto';
    font-weight: 700;
    font-style: normal;
    src: url("../assets/fonts/nettopro/NettoPro-Bold.otf") format("opentype");
}