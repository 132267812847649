.page-template-hydration
{

    $dark-blue: #115281;
    $blue: #2489D1;
    $blue2: #66BAF4;
    $light-blue: #E1F6FF;
    $grey: #959595;
    $green: #00A447;

    $light-blue: #B1DFFF;

    .full-screen {
        display: flex;
        width: 100%;
        min-height: calc(100vh - 4rem);
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            min-height: auto;
        }

        &.container{
            min-height: calc(100vh - 4rem);
        }
    }

    main{
        padding: 1rem 0;

        @include media-breakpoint-up(sm) {
            padding: 2rem 0;
        }
    }

    .goal-header{
        text-align: center;
        font-size: 14px;
        margin: 0 0 20px 0;
        background: rgba($light-blue, 0.35);
        border-radius: $borderRadius;
        padding: 0.25rem 1rem;
        color: $blue;

        &.success{
            background: rgba($green, 0.1);
            color: $green;
        }
    }

    .btn-history{
        position: absolute;
        top: 70px;
        right: 15px;
        display: inline-block;
        width: 50px;
        height: 50px;
        background: $white;
        z-index: 10;

        &.placeholder{
            filter: grayscale(100%);
        }

        @include media-breakpoint-up(md) {
            right: 0;
        }
    }

    .drop{
        position: relative;
        display: inline-block;

        &.placeholder{
            filter: grayscale(100%);
        }

        img{
            max-width: 230px;
            height: auto;
            position: relative;
            display: block;
            margin: 0 auto;
            z-index: 1;

        }
        
        .value{
            position: absolute;
            top: 0;
            left: 5%;
            width: 90%;
            height: 96%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .waves{
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 0%;
                max-height: 200%;
                opacity: 0.5;
                
                z-index: 0;
                background-image: url(../../assets/icons/hydration/waves.png);
                background-position: top right;
                transition: all 1s ease-out;

                animation-name: wave;
                animation-duration: 6s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }

            .waves2{
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 0%;
                max-height: 200%;
                
                z-index: 0;
                background-image: url(../../assets/icons/hydration/waves.png);
                background-position: top left;
                transition: all 1s ease-out;

                animation-name: wave2;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

            }

            @keyframes wave {
                from {
                    background-position: top right;
                }
                to {
                    background-position: top right 600px;
                }
            }

            @keyframes wave2 {
                from {
                    background-position: top left;
                }
                to {
                    background-position: top left 600px;
                }
            }

            .text{
                text-align: center;
                // font-family: 'Titillium Web', sans-serif;
                letter-spacing: normal;
                font-size: 20px;
                color: $black;
                line-height: 1.2;
                font-weight: 600;
                margin: 1rem 0 0 0;
                z-index: 1;
            }
        }
    }

    .hydration-goal{
        position: relative;
        margin: 0 0 1rem 0;
        z-index: 10;
    }

    .btn-goal{
        display: inline-block;
        font-size: 16px;
        padding: 0.5rem 2rem;
        border-radius: rem-calc(10);
        border: none;
        background: rgba($primary, 0.1);
        color: $black !important;
        text-transform: none;
        font-weight: normal;
        width: auto;
        height: auto;
        line-height: normal;

        &:hover, &:active, &:focus{
            box-shadow: none !important;
            background: rgba($primary, 0.1) !important;
        }

        .your-goal{
            color: $primary;
            font-weight: 600;
        }

        .SVGInline{
            font-size: 16px;
            margin: 0 0 0 20px;
        }

        &.btn-placeholder {
            background: $light-grey;
            background-image: linear-gradient(
                to right,
                $light-grey 0%,
                darken($light-grey, 4%) 20%,
                $light-grey 40%,
                $light-grey 100%
            );
            background-repeat: no-repeat;
            background-size: 960px 480px;
            border: 1px solid $grey;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeholderShimmer;
            animation-timing-function: linear;
    
            color: transparent !important;

            .your-goal{
                color: transparent;
            }
        }
    }

    .btn-add{
        position: relative;
        display: block;
        background: transparent;
        font-size: 16px;
        font-weight: 600;
        color: $black !important;
        text-transform: none;
        text-align: left;
        margin: 1rem 0;
        border: none;
        box-shadow: 0px 0px 5px rgba($black, 0.1);
        width: 100%;
        height: auto;
        line-height: normal;

        img{
            margin: 0 1rem 0 0;
        }

        &::after{
            content: '';
            position: absolute;
            top: 1.1rem;
            right: 1rem;
            background: $primary;
            border-radius: $borderRadius;
            color: $white;
            width: 28px;
            height: 28px;
            background-image: url(../../assets/icons/hydration/plus.svg);
        }

        &:hover, &:active, &:focus{
            box-shadow: 0px 0px 5px rgba($black, 0.1) !important;
            background: rgba($light-blue, 0.15) !important;
        }

        &.btn-placeholder {
            background: $light-grey;
            background-image: linear-gradient(
                to right,
                $light-grey 0%,
                darken($light-grey, 4%) 20%,
                $light-grey 40%,
                $light-grey 100%
            );
            background-repeat: no-repeat;
            background-size: 960px 480px;
    
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeholderShimmer;
            animation-timing-function: linear;
    
            color: transparent !important;
        }
    }

    .delete{
        text-transform: none;
        color: $grey;
        font-size: 16px;
        font-weight: normal;
        text-decoration: underline;
        
        &:disabled{
            background: transparent;
            opacity: 0;
        }
    }

    .ct-chart {
        .ct-series {
            .ct-point {
                stroke: $blue2;
            }
    
            .ct-area {
                fill: $blue2;
            }
    
            .ct-bar {
                fill: $blue2;
            }
        }

        .ct-series[*|series-name="goal"] {
            .ct-line {
                stroke: $green;
                stroke-dasharray: 5px 2px;
                animation: dashoffset-seven 500ms infinite linear;
    
                @include media-breakpoint-up(sm) {
                    stroke-dasharray: 10px 3px;
                    animation: dashoffset-thirtheen 500ms infinite linear;
                }
            }
        }
    }
}

.modal-dialog.hydration{

    .modal-content{
        border-radius: 10px;
        border: none;

        .modal-header{
            border-bottom: none;
        }

        .modal-body{
            padding: 0 20px;
        }

        .modal-footer{
            border-top: none;
            justify-content: center;
            padding: 20px;
        }
    }

    h2 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 2rem;

        @include media-breakpoint-up(md){
            font-size: 36px;
        }
    }

    h3 {
        color: $black;
        font-size: 24px;

        .big-number {
            font-size: 36px;
            vertical-align: baseline;
            font-weight: 600;
            color: $blue;
        }
    }

    .custom-range {
        position: relative;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 46px;
        outline: none;
        padding: 0 !important;
    
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            background: linear-gradient(
                to right,
                $blue 0%,
                $blue var(--value),
                rgba($grey, 0.15) var(--value),
                rgba($grey, 0.15) 100%
            );
            border-radius: 5px;
        }
    
        &::-moz-range-track {
            width: 100%;
            height: 10px;
            cursor: pointer;
            background: linear-gradient(
                to right,
                $blue 0%,
                $blue var(--value),
                rgba($grey, 0.15) var(--value),
                rgba($grey, 0.15) 100%
            );
            border-radius: 5px;
        }
    
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 24px;
            height: 24px;
            background: $white;
            border: 4px solid $blue;
            cursor: pointer;
            margin-top: -8px;
        }
    
        &::-moz-range-thumb {
            width: 22px;
            height: 22px;
            background: $white;
            border: 4px solid $blue;
            cursor: pointer;
            margin-top: -10px;
        }
    
        &::before {
            content: attr(min) " " attr(unit);
            position: absolute;
            left: 0;
            bottom: -0.5rem;
            font-size: 14px;
            color: $black;
        }
    
        &::after {
            content: attr(max) " " attr(unit);
            position: absolute;
            right: 0;
            bottom: -0.5rem;
            font-size: 14px;
            color: $black;
        }
    }
}
